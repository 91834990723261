<script lang="ts">
  export let embedded = false
  export let size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'sm'

  let sizeClasses = 'w-8 h-8'
  if (size === 'xs') {
    if (embedded) {
      sizeClasses = 'w-3 h-3'
    } else {
      sizeClasses = 'w-3 h-3'
    }
  } else if (size === 'sm') {
    if (embedded) {
      sizeClasses = 'w-4 h-4'
    } else {
      sizeClasses = 'w-4 h-4'
    }
  } else if (size === 'md') {
    if (embedded) {
      sizeClasses = 'w-5 h-5'
    } else {
      sizeClasses = 'w-10 h-10'
    }
  } else if (size === 'lg') {
    if (embedded) {
      sizeClasses = 'w-6 h-6'
    } else {
      sizeClasses = 'w-16 h-16'
    }
  } else if (size === 'xl') {
    if (embedded) {
      sizeClasses = 'w-6 h-6'
    } else {
      sizeClasses = 'w-16 h-16'
    }
  }
</script>

<div class={sizeClasses}>
  <svg viewBox="0 0 40 40" class="animate-spin">
    <circle
      cx="50%"
      cy="50%"
      r={18}
      class="stroke-current"
      fill="transparent"
      stroke-linecap="round"
      stroke-dasharray="128px"
      stroke-dashoffset="64px"
      stroke-width={4}
    />
  </svg>
</div>
